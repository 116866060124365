/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prefer-stateless-function */
import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

const DPA = () => {
  return (
    <Layout>
      <Seo
        title="Data Processing Agreement - Signaclass"
        description="Our Data Processing Agreement."
      />
      <Container>
        <Row>
          <Col sm="12" className="mt-5 pt-5 pb-3 text-center">
            <h1>
              <strong>Data Processing Agreement (GDPR)</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-5 pb-5">
            <div className="shadow border p-3">
              <p>
                According to the General Data Protection Regulation
                (&quot;GDPR&quot;) in the European Union, and other applicable
                data protection laws, you should have an agreement with Spacely
                OOD (&quot;Us&quot; / &quot;We&quot;) that governs how we will
                process your (&quot;The User&quot; / &quot;You&quot; /
                &quot;Your&quot;) data.
              </p>
              <p>
                This agreement (the &quot;DPA&quot;) is an essential part of our
                terms and conditions and outlines how we will process data
                received from individuals (&quot;The Customer&quot;) who use the
                software service to attend your classes.
              </p>
              <p>
                <strong>
                  <u>1. Definitions</u>
                </strong>
              </p>
              <p>
                1.1 &ldquo;Data Controller&rdquo;, &ldquo;Data Processor&rdquo;,
                &ldquo;processing&rdquo; and &ldquo;Personal Data&rdquo; shall
                have the meanings ascribed to them in the General Data
                Protection Regulation (&quot;GDPR&quot;);
              </p>
              <p>
                1.2 &ldquo;Security Breach&rdquo; shall mean any breach of
                security leading to accidental or illegal loss, alteration,
                unauthorized disclosure or access to Personal Data transmitted,
                stored or processed on our platform;
              </p>
              <p>
                1.3 &ldquo;Security Measures&rdquo; shall mean the security
                measures, both software and hardware, implemented by Spacely Ltd
                to protect from a security breach.
              </p>
              <p>
                1.4 &ldquo;Sub-processors&rdquo; means an entity engaged by us
                as a data-processor under and on behalf of the data controller.
              </p>
              <p>
                1.5 &ldquo;Data Subjects&rdquo; means an identified or otherwise
                identifiable natural person to which personal data, collected,
                stored, accessed or otherwise processed by us with the purpose
                of providing services to you.
              </p>
              <p>
                1.6 &ldquo;Sensitive Data&rdquo; shall mean Personal Data
                revealing racial or ethnic origin, political opinions, religious
                or philosophical beliefs, trade-union membership, genetic data,
                biometric data, data concerning health, sex life or sexual
                orientation
              </p>
              <p>
                1.7 &ldquo;User&rdquo; means you, the business or entity for
                which we are a data processor.
              </p>
              <p>
                1.8 &ldquo;Customer&rdquo; means an individual who registers for
                an account underneath your user, able to create participants and
                subscribes to lessons, or anyone who visits areas of our
                software service provided for you.
              </p>
              <p>
                1.9 &ldquo;The Software Service&rdquo; means
                &apos;Signaclass&apos; a software-as-a-service that allows
                activity businesses to manage subscriptions, attendance and
                customer progress in their classes.
              </p>
              <p>
                <strong>
                  <u>2. Data Processing</u>
                </strong>
              </p>
              <p>
                2.1 You are a data controller of any personal data provided by
                an individual who registers for or buys services from you using
                the software service. You agree that you will handle such data
                lawfully and as described by privacy legislation applicable to
                you.
              </p>
              <p>
                2.2 We are a data processor for you. This includes parts of the
                software service where we provide analytics, transmit emails to
                the customer or give you insights to improve your business.
              </p>
              <p>
                2.3 We shall process personal data as part of our software
                service to you. The data requested for processing will be names,
                billing addresses, telephone numbers, details of participants
                (including name, emergency contact and address), email address
                and any other information the customer enters into form fields
                to inform you or your staff outlined fully in section 6 of this
                agreement. This data is gathered and processed by us to
                facilitate you holding safe classes, such as being able to
                contact responsible people in the event of an emergency and for
                you to ensure the integrity of your services.
              </p>
              <p>
                2.4 We shall process this data for the duration of our agreement
                with you.
              </p>
              <p>
                2.5 If you cancel or break your agreement with us (for example
                by cancelling your subscription to our software service or
                failing to pay) we will delete all information associated with
                your unique identifier (including that of your customers) 31
                days after the cancellation becomes effective. Your account on
                signaclass.com will become immediately inaccessible and you will
                be required to set up a new one to use the software service
                again.
              </p>
              <p>
                2.6 We continue to hold financial records and records of you as
                a customer for compliance and to meet our tax obligations, in
                line with their respective legally set time limits.
              </p>
              <p>
                <strong>
                  <u>3. Data Processing Clauses</u>
                </strong>
              </p>
              <p>
                3.1 Whenever we process personal data on your behalf, we do so
                for the express purpose of providing our software service to
                you, unless we are required to do otherwise by other legal
                obligations.
              </p>
              <p>
                3.2 You, the User, agree to use data collected and processed by
                us lawfully and following applicable laws.
              </p>
              <p>
                3.3 You instruct us to process personal data as necessary to
                provide you with our software-as-a-service product
                (&quot;Signaclass&quot;).
              </p>
              <p>
                3.4 We shall enact security measures to protect personal data.
              </p>
              <p>
                3.5 After becoming aware of a security breach, we will notify
                you without undue delay.
              </p>
              <p>
                3.6 In the event of a security breach, we will assist you with
                your obligations as a data controller so that you can notify the
                customer.
              </p>
              <p>
                3.7 Our staff will be screened and subject to contractual
                obligations to protect personal data. Processing activities
                under this agreement shall only be performed by individuals
                (such as employees, agents, or staff members) that have been
                instructed by us on the appropriate way to process data and have
                been contractually subjected to confidentiality pursuant to art.
                28 par. 3 (b) and art. 32 GDPR.
              </p>
              <p>
                3.8 We will ensure that our sub-processors (listed below in
                section 4) process personal data following this DPA and with
                applicable laws.
              </p>
              <p>
                3.9 We will delete part or all of your data on your request
                unless legal obligations require that we keep the data.
              </p>
              <p>
                3.10 You are the owner of all data collected through the
                software service related to your business, and we will never
                sell, disclose, share, or otherwise make known your data other
                than as outlined in this agreement.
              </p>
              <p>
                3.11 You agree that our subprocessors (listed below in section
                4) can process personal data on our behalf as part of the
                software service we deliver to you in accordance with Article 28
                paragraph 4 GDPR.
              </p>
              <p>
                3.12 You agree we may appoint additional sub-processors to
                process personal data if we give you prior notice of such
                appointments and do not object by email by contacting
                info@signaclass.com within 14 days.
              </p>
              <p>
                3.13 If we do not agree to your objection, you can withdraw your
                consent to this agreement at any time by email, or by cancelling
                your account and closing any existing fees and balances with us.{" "}
              </p>
              <p>
                3.14 We provide you with tools to show your terms and conditions
                and your privacy policy within the software service. You agree
                to make use of these tools to ensure your customers are made
                aware of your data collection and usage practices, in line with
                your legal obligations.
              </p>
              <p>
                <strong>
                  <u>4. Subprocessors and Connected Services</u>
                </strong>
              </p>
              <p>
                Our subprocessors are listed below and will be used in
                delivering our services to you:
              </p>
              <p>
                <strong>
                  Google Cloud Platform (Google Ireland Limited, Ireland):{" "}
                </strong>{" "}
                We use google cloud platform to store data and undertake
                processing of your data by using it to run our software service.{" "}
                <a href="https://policies.google.com/privacy">
                  Their privacy policy can be found here.
                </a>
              </p>
              <p>
                <strong>MongoDB Atlas (MongoDB, Inc.):</strong> MongoDB Atlas,
                provided by MongoDB, Inc., serves as a subprocessor in our data
                processing operations. As a cloud-based database platform,
                MongoDB Atlas enables us to efficiently store, manage, and
                access our data while benefiting from robust security measures
                and scalability.{" "}<a href="https://www.mongodb.com/products/platform/trust/gdpr">
                  Their privacy policy can be found here.
                </a>
              </p>
              <p>
                <strong>Mailgun (Mailgun Technologies, Inc., Germany): </strong>{" "}
                Mailgun provide a series of transactional APIs to send email
                automatically from the software service to your customers, or to
                you, to notify you of events. Our software service will send
                email addresses and customer uploaded information to create
                personalized messages.{" "}
                <a href="https://www.mailgun.com/legal/privacy-policy/">
                  Their privacy policy can be found here.
                </a>
              </p>
              <p>
                <strong>Cloudflare (Cloudflare, Inc, United States): </strong>{" "}
                Cloudflare is a traffic optimization and distribution service
                that helps us secure our software service from threats and
                accelerate delivery by rehosting content from servers closer to
                the visitor, and filtering out bots, spam and certain types of
                IT infrastructure attack.{" "}
                <a href="https://www.cloudflare.com/privacypolicy/">
                  Their privacy policy can be found here.
                </a>
              </p>
              <p>
                <strong>
                  Stripe (Stripe Technology Europe Ltd, Ireland):{" "}
                </strong>{" "}
                Stripe provide a series of payment APIs software service app can
                use to facilitate payments from your customers. Our software
                service will access <em>your</em> stripe account to create
                customers and set up and manage payments. It should be noted
                that the stripe account connected will be <em>your</em> account,
                standing free and independent of our software service, and so
                stripe is an independent data processor of yours that we connect
                to, with your permission.{" "}
                <a href="https://stripe.com/ie/privacy">
                  Their privacy policy can be found here.
                </a>
              </p>
              <p>
                {" "}
                <strong>
                  <u>5. Rights of Data Subjects</u>
                </strong>
              </p>
              <p>
                {" "}
                5.1 To the extent allowed by law, we will inform you of any
                formal requests from customers (data subjects) exercising their
                rights to correct, access or erase their personal data (or other
                rights), and will not respond to such requests unless instructed
                by you in writing to do so.
              </p>
              <p>
                5.2 We will, upon your written request, provide reasonable
                support to assist you in responding to requests from your
                customers. You shall be responsible for any costs arising from
                our assistance.
              </p>
              <p>
                {" "}
                <strong>
                  <u>6. Categories of Personal Data</u>
                </strong>
              </p>
              <p>
                6.1 We will process the following personal data in our role as a
                data processor facilitating your management of classes, lessons,
                groups or other sessions, as part of our software service:
              </p>
              <p>
                <strong>
                  Data that facilitates website usage &amp; connectivity:
                </strong>{" "}
                Email Address, Passwords, Metadata (such as details of your
                visits to the Website, traffic data, location data, IP address
                and other online identifiers, browser information, session data,
                preferences, settings, weblogs and other communication data),
              </p>
              <p>
                <strong>Data necessary for billing your customers:</strong>{" "}
                First Name, Last Name, Street Address, Postcode, City, Contact
                Telephone Number, Email Address, General Information about
                subscriptions, such as dates paid for.
              </p>
              <p>
                <strong>
                  Participant data, entered by your customers, necessary for
                  facilitating attendance your classes:{" "}
                </strong>{" "}
                First Name, Last Name, Date of Birth, Full Address, Emergency
                Contact Names and Telephone Numbers, any other information
                entered in forms by the customer.
              </p>
              <p>
                <strong>Data entered by you about participants:</strong>{" "}
                Attendance Records, Awards, Progress Report, any other
                information entered into forms by you.
              </p>
              <p>
                <strong>Data entered by you for Tutor Accounts:</strong> Email
                Address, First Name, Last Name.
              </p>
              <p>
                6.2 During usage of our software service, you, or your
                customers, may submit data - the extent of which is determined
                and controlled by you and your customers at their respective
                sole discretion. It is not anticipated that the processing will
                include sensitive data, but in the event you or your customers
                enter it into the software service you will apply applicable law
                and necessary controls.
              </p>
              <p>
                {" "}
                <strong>
                  <u>7. Technical &amp; Organisational Security Measures</u>
                </strong>
              </p>
              <p>
                7.1 We store data in systems protected by AES256 encryption in
                all cases. This standard of encryption is adopted by bodies such
                as the US government to protect confidential data if physical
                storage devices were compromised.
              </p>
              <p>
                7.2 We protect data during transmission, including between a
                person&apos;s browser and the software service, and between the
                software service and our databases, using transport layer
                security(SSL/TLS).
              </p>
              <p>
                7.3 All passwords and other access tokens are hashed and salted
                using an industry respected hashing algorithm designed
                specifically to protect passwords. No passwords are ever stored
                in plain text.
              </p>
              <p>
                7.4 Our software service is designed to reject requests from web
                browsers not served in HTTPS.
              </p>
              <p>
                7.5 We implement firewalls, private networks, service
                separation, access control and authentication using SSH keys and
                other techniques to ensure network security and prevent
                unauthorized access.
              </p>
              <p>
                7.6 All persons logged in (including customers and users) are
                logged out after a period of inactivity.
              </p>
              <p>
                7.7 All persons (including customers and users) are given an
                easy means to change their password in the event they think it
                may have been compromised by their behaviour.
              </p>
              <p>
                7.8 We employ a code review process to ensure our code is up to
                date and features no known vulnerabilities, as well as testing
                our code for potential vulnerabilities before it is deployed.
              </p>
              <p>
                7.9 We protect data from destruction or unauthorized deletion
                with tools like regular snapshotting, replication and short-term
                immutable backups. We use redundant infrastructure to protect
                the continuity of our databases.
              </p>
              <p>
                7.10 We select our subprocessors only when we are convinced they
                offer the highest level of protection. For example Google Cloud
                are industry recognized leaders in hosting that implement
                multiple layers of encryption across their services and maintain
                the highest level of security in their data centers. Google
                Cloud implement standard contractual clauses under GDPR
                guaranteeing a high level of privacy and security, and maintain
                international standards such as ISO/IEC 27001. We regularly
                review our subprocessors to ensure they continue to meet the
                needs of our software service.
              </p>
              <p>
                7.11 We implement logging, monitoring of access to our
                infrastructure ensure no unauthorized access has occured. Under
                no circumstances do we allow unauthorized persons to access data
                and authorization is only allocated by us to employees based on
                appropriate privileges, according to role, and the direct
                approval of management.
              </p>
              <p>
                7.12 We maintain a clear bug and issue reporting process to
                ensure that problems are identified and fixed with immediacy,
                especially where they may result in a security breach or
                compromise the privacy of data subjects.
              </p>
              <p>
                7.13 Your data is kept seperate and identifiable from other
                users by use of permanent unique identifiers. Users may only
                access information related to their instance of our software
                service.
              </p>
              <p>
                7.14 You acknowledge our technical and organisational measures
                are subject to technical and technological progress and
                development. Hence, we may adopt alternative adequate measures
                which are up to date with the changed technological environment.
                When doing so, the processing security level may not be reduced.
                Substantial changes must be documented.
              </p>
              <p>
                {" "}
                <strong>
                  <u>8. Data Transfer Abroad</u>{" "}
                </strong>
              </p>
              <p>
                8.1 You acknowledge that some subprocessors we may appoint
                (listed in section 4) may process data outside the EU/EEA. You
                authorize such processing in strict compliance to the legal
                bases for data transfer set forth in articles. 45 and ss. GDPR
                based on standard contractual clauses and/or an adequacy
                decision.
              </p>
              <p>
                8.2 If you, the user, are subject to UK Law (UK GDPR) you
                acknowledge that the data processing takes place in the EU/EEA
                and other third countries. You authorize such processing in
                strict compliance to the legal bases for data transfer set forth
                in articles. 45 and ss. GDPR based on standard contractual
                clauses and/or an adequacy decision.
              </p>
              <p>
                {" "}
                <strong>
                  <u>9.Audits/Inspections</u>{" "}
                </strong>
              </p>
              <p>
                9.1 We will make available to you, as far as is reasonably
                possible, all the necessary information to demonstrate our
                compliance with this data processing agreement and GDPR.
              </p>
              <p>
                9.2 We may charge a reasonable fee to you for enabling of
                inspections, audits and respond to other compliance related
                requests.
              </p>
              <p>
                {" "}
                <strong>
                  <u>10. Liability</u>{" "}
                </strong>
              </p>
              <p>
                10.1 Each party to this agreement commits to indemnify the other
                party for damages or expenses resulting from its own culpable
                infringement of this DPA, including any culpable infringement
                committed by its legal representative, subcontractors, employees
                or any other agents. Furthermore, each party commits to
                indemnify the other party against any claim exerted by third
                parties due to, or in connection with, any culpable infringement
                by the respectively other party.
              </p>
              <p>10.2 Art. 82 GDPR stays unaffected.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

// Export
export default DPA
